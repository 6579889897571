/*--------------------------------

Nucleo icon font
Generated using nucleoapp.com

-------------------------------- */

$icon-font-path: '../../../icons' !default;

@font-face {
  font-family: 'Nucleo';
  src: url('#{$icon-font-path}/Nucleo.eot');
  src: url('#{$icon-font-path}/Nucleo.eot') format('embedded-opentype'),
  url('#{$icon-font-path}/Nucleo.woff2') format('woff2'),
  url('#{$icon-font-path}/Nucleo.woff') format('woff'),
  url('#{$icon-font-path}/Nucleo.ttf') format('truetype'),
  url('#{$icon-font-path}/Nucleo.svg') format('svg');
}

/* base class */
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Nucleo';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.icon-rotate-90 {
  transform: rotate(90deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-y {
  transform: scaleY(-1);
}

.icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
$icons: (
  'kiosk-icon-card': 'ea02',
  'kiosk-icon-coins': 'ea03',
  'check-circle-2': 'ea04',
  'dots': 'ea05',
  'instagram': 'ea06',
  'star': 'ea07',
  'star-empty': 'ea08',
  'x': 'ea09',
  'account': 'ea0a',
  'cross': 'ea0b',
  'clock': 'ea0c',
  'taxi': 'ea0d',
  'arrow-left': 'ea0e',
  'coupon': 'ea0f',
  'dezoom': 'ea10',
  'arrow-right': 'ea11',
  'food-society': 'ea12',
  'c-check': 'ea13',
  'heart': 'ea14',
  'bag-delivery': 'ea15',
  'check-s': 'ea16',
  'list': 'ea17',
  'lock': 'ea18',
  'chef-hat': 'ea19',
  'hotel-bell': 'ea1a',
  'Untitled': 'ea1b',
  'plan': 'ea1c',
  'plus': 'ea1d',
  'n-check': 'ea1e',
  'shopping-bag': 'ea1f',
  'sun': 'ea20',
  'move': 'ea21',
  'satisfied': 'ea22',
  'zoom': 'ea23',
  'comment': 'ea24',
  'ticket': 'ea25',
  'pencil': 'ea26',
  'terrace': 'ea27',
  'arrow-right-2': 'ea28',
  'n-check-2': 'ea29',
  'check-single': 'ea2a',
  'minus': 'ea2b',
  'cross-2': 'ea2c',
  'facebook': 'ea2d',
  'pin-2': 'ea2e',
  'clock-2': 'ea2f',
  'filter': 'ea30',
  'seat': 'ea31',
  'distance': 'ea32',
  'position': 'ea33',
  'black-lock': 'ea34',
  'c-remove': 'ea35',
  'tiktok': 'ea36',
  'arrow-down-s': 'ea37',
  'home': 'ea38',
  'less': 'ea39',
  'shopping-bag-2': 'ea3a',
  'bullet-list': 'ea3b',
  'comment-2': 'ea3c',
  'twitter': 'ea3d',
  'chevron-down': 'ea3e',
  'youtube': 'ea3f',
  'check-xs': 'ea40',
  'repeat-circle': 'ea41',
  'warning-square': 'ea42',
  'chef-hat-2': 'ea43',
  'check-circle': 'ea44',
  'coupon-2': 'ea45',
  'coupon-3': 'ea46',
  'bike': 'ea47',
  'black-heart': 'ea48',
  'search': 'ea49',
  'pig': 'ea4a',
  'smile': 'ea4b',
  'map-pin': 'ea4c',
  'phone': 'ea4d',
  'question': 'ea4e',
  'facebook-2': 'ea4f',
  'seat-2': 'ea50',
  'group-order': 'ea51',
  'vespa': 'ea52',
  'todo': 'ea53',
  'person-circle': 'ea54',
  'n-check-3': 'ea55',
  'linkedin': 'ea56',
  '-2': 'ea57',
  'bag-add': 'ea58',
  'wink': 'ea59',
  'plus-2': 'ea5a',
  'arrow-up-s': 'ea5b',
  'check-plain': 'ea5c',
  'bike-3': 'ea5d',
  'chevron-right-circle': 'ea5e',
  'hamburger': 'ea5f',
  'upload': 'ea60',
  'dashboard': 'ea61',
  'qr-code': 'ea62',
  'time-arrow': 'ea63',
  'distance-2': 'ea64',
  'todo-list': 'ea65',
  'arrow-right-circle': 'ea66',
  'coins': 'ea67',
  'privacy': 'ea68',
  'logout': 'ea69',
  'hourglass': 'ea6a',
  'redo-arrow': 'ea6b',
  'pin-3': 'ea6c',
  'receipt_long': 'ea6d',
  'bike-2': 'ea6e',
  'garbage': 'ea6f',
  'shop': 'ea70',
  'c-info-3': 'ea71',
  'warning-sign': 'ea71',
);

@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

@each $name, $code in $icons {
  .icon-#{'' + $name}::before {
    content: unicode($code);
  }
}