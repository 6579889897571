.gm-style div[aria-hidden='true'] {
  background-color: white !important;
  padding: 2px 4px;
}

.gm-style span[aria-hidden='true'] {
  font-variant-numeric: lining-nums;
}

/* Google map places */

.pac-container {
  position: fixed;
  width: 100%;
}

.pac-item {
  padding: 12px 12px;
}
