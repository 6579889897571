#termsfeed-com---nb {
  @apply text-base rounded-3xl m-2 border shadow-lg text-neutral-800 bg-white;
  @apply mdMax:rounded-b-none mdMax:min-w-full mdMax:m-0;

  button {
    @apply rounded-3xl;
  }

  .cc-nb-okagree {
    @apply bg-primary-600 text-white;
  }

  .cc-nb-reject {
    @apply bg-primary-600 text-white;
  }

  .cc-nb-changep {
    @apply bg-primary-100 text-primary-600;
  }
}

.termsfeed-com---pc-dialog {
  @apply mdMax:m-0 mdMax:min-w-screen;

  .cc-pc-container {
    @apply text-base rounded-3xl m-2 border shadow-lg text-neutral-800 bg-white;
    @apply mdMax:rounded-b-none mdMax:min-w-full mdMax:m-0;
  }

  .cc-pc-head {
    @apply md:rounded-t-3xl;
  }

  .cc-cp-body {
  }

  .cc-cp-foot {
    @apply md:rounded-b-3xl;

    .cc-cp-foot-save {
      @apply text-base bg-primary-600 text-white rounded-3xl;
    }
  }
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox:checked + label::before {
  @apply bg-primary-600;
}
