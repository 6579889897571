.dot {
  &:after {
    content: ' ';
    height: 3px;
    width: 3px;
    @apply bg-neutral-400 rounded-full m-1 inline-block;
  }

  &.dot-2:after {
    @apply mx-2;
  }

  &:last-child:after {
    content: none;
  }
}
